import {FC} from "react";
import {useTranslation} from "react-i18next";
import {css} from "@emotion/react";
import {Button} from "@pg-design/button";
import {flex, mb, mr, onDesktop, ph, pointer, textAlign, underline, w100} from "@pg-design/helpers-css";
import {Modal} from "@pg-design/modal";
import {Highlight, Text} from "@pg-design/text";

import {getPublicationAccessStatus} from "../../../../../../utils/get_publication_access_status";
import {useAppDispatch, useAppSelector} from "../../../../../../utils/hooks/store_hooks";
import {UserAccessStatus} from "../../../../../../utils/shared_types/user_model";
import {selectAuthStatus, setAuthModal} from "../../../auth/auth_slice";
import {selectUserData} from "../../../users/users_slice";
import {useDownloadFreePublicationMutation} from "../../api/download_publication";
import {PublicationPricingType} from "../../types/Publication";
import {DownloadFreePublicationForm} from "../download_free_publication_modal/DownloadFreePublicationForm";
import {DownloadPaidPublicationForm} from "../download_paid_publication_modal/DownloadPaidPublicationForm";
import {PublicationOrderSuccess} from "./PublicationOrderSuccess";

interface IDownloadPublicationModalProps {
    isOpen: boolean;
    setOpen: (value: boolean) => void;
    id: number;
    price: number;
    promotional_price: number | null;
    pricing_type: PublicationPricingType;
    name: string;
}

export const DownloadPublicationModal: FC<IDownloadPublicationModalProps> = ({
    isOpen,
    setOpen,
    id,
    price,
    promotional_price,
    pricing_type,
    name
}) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [, downloadPublicationMutation] = useDownloadFreePublicationMutation({
        fixedCacheKey: `orderPublication${id}`
    });
    const {isLoggedIn} = useAppSelector(selectAuthStatus);
    const {isFreeForBD, isFree, isPaid} = getPublicationAccessStatus(pricing_type);
    const userData = useAppSelector(selectUserData);

    if (downloadPublicationMutation.isSuccess) {
        return (
            <Modal
                isOpen={isOpen}
                onModalClose={() => {
                    downloadPublicationMutation.reset();
                    setOpen(false);
                }}
            >
                <Modal.Content>
                    <PublicationOrderSuccess
                        isPaid={isPaid}
                        isFreeForBD={isFreeForBD}
                        onClick={() => {
                            downloadPublicationMutation.reset();

                            setOpen(false);
                        }}
                    />
                </Modal.Content>
            </Modal>
        );
    }

    return (
        <Modal
            isOpen={isOpen}
            onModalClose={() => {
                downloadPublicationMutation.reset();
                setOpen(false);
            }}
        >
            <Modal.Header>
                <Text variant="headline_6" as="p">
                    {t("publications.download_publication_modal.download_publication")}
                </Text>
            </Modal.Header>

            <Modal.Content css={publicationModalWrapper}>
                <div css={subHeaderWrapper}>
                    {!isFree && (
                        <>
                            <Text variant="body_copy_2" css={[mb(3)]}>
                                {promotional_price && isPaid && (
                                    <>
                                        Cena dla klientów RynekPierwotny.pl:{" "}
                                        <Highlight strong>{promotional_price}zł.</Highlight>
                                        <br />
                                    </>
                                )}
                                {isFreeForBD && (
                                    <>
                                        Cena dla klientów RynekPierwotny.pl: <Highlight strong>Bezpłatnie</Highlight>
                                        <br />
                                    </>
                                )}
                                Cena regularna: <Highlight strong>{price}zł.</Highlight>
                            </Text>
                        </>
                    )}

                    {!isLoggedIn && !isFree && (
                        <div css={authButtonWrapper}>
                            <Button
                                variant="outlined_secondary"
                                onClick={() => dispatch(setAuthModal("login"))}
                                css={[w100, mb(2), ph(5)]}
                                size="x-small"
                            >
                                Zaloguj się
                            </Button>

                            <Text variant="info_txt_1" align="center">
                                Nie masz jeszcze konta? <br />{" "}
                                <span onClick={() => dispatch(setAuthModal("registerUser"))} css={[underline, pointer]}>
                                    Zarejestruj się
                                </span>
                            </Text>
                        </div>
                    )}
                </div>

                {!isLoggedIn && isFree && (
                    <>
                        <Text variant="body_copy_2">
                            {t("publications.download_publication_modal.publication_mail")}
                        </Text>

                        <Text variant="body_copy_2" css={mb(2)}>
                            Masz już konto?{" "}
                            <span onClick={() => dispatch(setAuthModal("login"))} css={[underline, pointer]}>
                                Zaloguj się
                            </span>{" "}
                            i pobieraj raporty jednym kliknięciem myszy.
                        </Text>
                    </>
                )}

                {/*modal forms, we display a small form in the case where, publication is free or free for bd, we have full access, but no marketing consent*/}
                {isFree ||
                (isFreeForBD &&
                    userData &&
                    !userData.bigdata_marketing_consent &&
                    userData.access_status === UserAccessStatus.ACCEPTED) ? (
                    <DownloadFreePublicationForm id={id} />
                ) : (
                    <DownloadPaidPublicationForm publication_name={name} id={id} />
                )}
            </Modal.Content>
        </Modal>
    );
};

const publicationModalWrapper = css`
    ${onDesktop(css`
        width: var(--modal-width-larg);
        overflow: initial;
    `)};
`;

const subHeaderWrapper = css`
    ${mb(3)};
    ${onDesktop(css`
        ${flex("flex-start", "space-between")};
        max-height: 75px;
        ${mb(0)};
    `)}
`;

const authButtonWrapper = css`
    ${textAlign("center")};

    ${onDesktop(css`
        ${mr(8)};
        position: relative;
        top: -4rem;
    `)};
`;
