import {i18n} from "i18next";

import {IMonthCityStats} from "../../../../api/src/db_queries/month_city_stats_query";
import {IMonthCityTypeStats} from "../../../../api/src/db_queries/month_city_type_stats_query";
import {calculatePercentageDifference} from "../../../../utils/calculate_percentage_difference";
import {substractOneMonth} from "../../../../utils/dates";
import {formatNumber} from "../../../../utils/format";
import {sortByField} from "../../../../utils/misc";
import {FilterOfferType} from "../app/constants/enums";

export const analystCommentOffer = (
    data: IMonthCityStats[] & IMonthCityTypeStats[],
    currentDate: string,
    offerType: FilterOfferType | null,
    i18n: i18n
): string => {
    // gather data for the month before and current month to be able to compare values
    const lastMonthData = data.filter((city) => city.date === substractOneMonth(currentDate));
    const currentMonthData = data.filter((city) => city.date === currentDate);

    if (lastMonthData.length === 0 || currentMonthData.length === 0) {
        return "";
    }

    // sort current month's data by field that cities should be sorted
    const sortedCurrentMonthData = sortByField(currentMonthData, "available_offers");
    // define data from last month that will be used for values comparisions
    const firstCityDataInLastMonth = lastMonthData.find(
        (city) => city.slug_city === sortedCurrentMonthData[0].slug_city
    ) as IMonthCityStats;
    const secondCityDataInLastMonth = lastMonthData.find(
        (city) => city.slug_city === sortedCurrentMonthData[1].slug_city
    ) as IMonthCityStats;
    const thirdCityDataInLastMonth = lastMonthData.find(
        (city) => city.slug_city === sortedCurrentMonthData[2].slug_city
    );
    const fourthCityDataInLastMonth = lastMonthData.find(
        (city) => city.slug_city === sortedCurrentMonthData[3].slug_city
    ) as IMonthCityStats;
    const fifthCityDataInLastMonth = lastMonthData.find(
        (city) => city.slug_city === sortedCurrentMonthData[4].slug_city
    ) as IMonthCityStats;
    const sixthCityDataInLastMonth = lastMonthData.find(
        (city) => city.slug_city === sortedCurrentMonthData[5].slug_city
    ) as IMonthCityStats;
    // set offer type
    const offerTypeKey = offerType ? (offerType === FilterOfferType.FLATS ? "flat" : "house") : "flat_and_house";
    // calculate required totals and compare them
    const offersTotal = currentMonthData.reduce((total, city) => city.available_offers + total, 0);
    const lastMonthOffersTotal = lastMonthData.reduce((total, city) => city.available_offers + total, 0);
    const offersTotalMonthlyChange = calculatePercentageDifference(offersTotal, lastMonthOffersTotal, true);
    const offersTotalChangeTrend = offersTotal > lastMonthOffersTotal ? "larger" : "smaller";
    const soldOffersTotal = formatNumber(currentMonthData.reduce((total, city) => city.sold + total, 0));
    const availableInvestmentsTotal = formatNumber(
        currentMonthData.reduce((total, city) => city.available_investments + total, 0)
    );
    const addedOffersTotal = currentMonthData.reduce((total, city) => city.added + total, 0);
    const lastMonthAddedOffersTotal = lastMonthData.reduce((total, city) => city.added + total, 0);
    const addedOffersMonthlyChange = calculatePercentageDifference(addedOffersTotal, lastMonthAddedOffersTotal, false);
    const addedOffersChangeTrend = addedOffersTotal > lastMonthAddedOffersTotal ? "more" : "less";
    // first city
    const firstCityAvailableOffers = sortedCurrentMonthData[0].available_offers;
    const firstCitySold = sortedCurrentMonthData[0].sold;
    const firstCityAddedOffers = sortedCurrentMonthData[0].added;
    const firstCity =
        sortedCurrentMonthData[0].slug_city === "warszawa"
            ? i18n.t("common.capital", {context: "genitive"})
            : i18n.t(`city.${sortedCurrentMonthData[0].slug_city}.genitive`);
    const firstCityPronoun = i18n.t(`city.${sortedCurrentMonthData[0].slug_city}.pronoun`);
    // second city
    const secondCity = i18n.t(`city.${sortedCurrentMonthData[1].slug_city}.locative`);
    const secondCityAdded = sortedCurrentMonthData[1].added;
    const secondCityLastMonthAdded = secondCityDataInLastMonth.added;
    const secondCityAddedMonthlyChange = calculatePercentageDifference(
        secondCityAdded,
        secondCityLastMonthAdded,
        false
    );
    const secondCityAddedChangeTrend = secondCityAdded > secondCityLastMonthAdded ? "more" : "less";
    const secondCityAvailableOffers = sortedCurrentMonthData[1].available_offers;
    // third city
    const thirdCity = i18n.t(`city.${sortedCurrentMonthData[2].slug_city}.locative_uppercased`);
    const thirdCityAvailableOffers = sortedCurrentMonthData[2].available_offers;
    const thirdCitySold = sortedCurrentMonthData[2].sold;
    const thirdCityAdded = sortedCurrentMonthData[2].added;
    // fourth city
    const fourthCity = i18n.t(`city.${sortedCurrentMonthData[3].slug_city}.locative`);
    const fourthCityAvailableOffers = sortedCurrentMonthData[3].available_offers;
    const fourthCityLastMonthAvailableOffers = fourthCityDataInLastMonth.available_offers;
    const fourthCityAvailableOffersChangeTrend =
        fourthCityAvailableOffers > fourthCityLastMonthAvailableOffers ? "risen" : "fallen";
    const fourthCitySold = sortedCurrentMonthData[3].sold;
    const fourthCityAdded = sortedCurrentMonthData[3].added;
    const fourthCityAvailableOffersMonthlyChange = calculatePercentageDifference(
        fourthCityAvailableOffers,
        fourthCityLastMonthAvailableOffers,
        true
    );
    const fourthCityDescriptive = i18n.t(`city.${sortedCurrentMonthData[3].slug_city}.poetic_description`, {
        context: "genitive"
    });
    // fifth city
    const fifthCity = i18n.t(`city.${sortedCurrentMonthData[4].slug_city}.locative`);
    const fifthCityUppercased = i18n.t(`city.${sortedCurrentMonthData[4].slug_city}.locative`, {context: "uppercased"});
    const fifthCityPronoun = i18n.t(`city.${sortedCurrentMonthData[4].slug_city}.pronoun`);
    const fifthCityAvailableOffers = sortedCurrentMonthData[4].available_offers;
    const fifthCityLastMonthAvailableOffers = fifthCityDataInLastMonth.available_offers;
    const fifthCityAvailableOffersMonthlyChange = calculatePercentageDifference(
        fifthCityAvailableOffers,
        fifthCityLastMonthAvailableOffers,
        false
    );
    const fifthCityAddedOffers = sortedCurrentMonthData[4].added;
    const fifthCityDescriptive = i18n.t(`city.${sortedCurrentMonthData[4].slug_city}.poetic_description`, {
        context: "genitive"
    });
    const fifthCityAvailableOffersChangeTrend =
        fifthCityAvailableOffers > fifthCityLastMonthAvailableOffers ? "more" : "less";
    //sixth city
    const sixthCity = i18n.t(`city.${sortedCurrentMonthData[5].slug_city}.nominative`);
    const sixthCityPronoun = i18n.t(`city.${sortedCurrentMonthData[5].slug_city}.pronoun`);
    const sixthCityAvailableOffers = sortedCurrentMonthData[5].available_offers;
    const sixthCityLastMonthAvailableOffers = sixthCityDataInLastMonth.available_offers;
    const sixthCityAvailableOffersMonthlyChange = calculatePercentageDifference(
        sixthCityAvailableOffers,
        sixthCityLastMonthAvailableOffers,
        true
    );
    const sixthCitySold = sortedCurrentMonthData[5].sold;
    const sixthCityAdded = sortedCurrentMonthData[5].added;
    const sixthCityDevelopersOrigin = i18n.t(`city.${sortedCurrentMonthData[5].slug_city}.adjective`, {
        context: "genitive",
        count: 100
    });
    const sixthCityAvailableOffersChangeTrend =
        sixthCityAvailableOffers > sixthCityLastMonthAvailableOffers ? "risen" : "fallen";

    const variables = {
        offersTotalType: i18n.t(`common.${offerTypeKey}`, {count: offersTotal}),
        offersTotal: formatNumber(offersTotal),
        offersTotalSuffix: i18n.t("common.offer", {count: offersTotal}),
        offersTotalChangeTrend: i18n.t(`common.${offersTotalChangeTrend}`, {context: "female"}), // ona - oferta
        offersTotalMonthlyChange,
        availableInvestmentsTotal,
        soldOffersTotal,
        addedOffersTotal: formatNumber(addedOffersTotal),
        addedOffersMonthlyChange,
        addedOffersChangeTrend: i18n.t(`common.${addedOffersChangeTrend}`),
        addedOffersSuffix: i18n.t("common.offer", {count: addedOffersTotal}),

        firstCity,
        firstCityPronoun,
        firstCityAvailableOffersPrefix: "było",
        firstCityAvailableOffers: formatNumber(firstCityAvailableOffers),
        firstCityOfferType: i18n.t(`common.${offerTypeKey}`, {count: firstCityAvailableOffers}),
        firstCitySold: formatNumber(firstCitySold),
        firstCitySoldSuffix: i18n.t(`common.${offerTypeKey}`, {count: firstCitySold}),
        firstCityGotSold: "zostało sprzedanych",
        firstCityAddedOffers,
        firstCityAddedOffersSuffix: i18n.t("common.offer", {count: 100}),

        secondCity,
        secondCityAdded: formatNumber(secondCityAdded),
        secondCityAddedMonthlyChange,
        secondCityAddedChangeTrend: i18n.t(`common.${secondCityAddedChangeTrend}`),
        secondCityAvailableOffers: formatNumber(secondCityAvailableOffers),

        thirdCity,
        thirdCityAvailableOffers: formatNumber(thirdCityAvailableOffers),
        thirdCitySold: formatNumber(thirdCitySold),
        thirdCityAdded: formatNumber(thirdCityAdded),
        thirdCityAddedSuffix: i18n.t(`common.${offerTypeKey}`, {count: thirdCityAdded}),

        fourthCity,
        fourthCityAvailableOffers: formatNumber(fourthCityAvailableOffers),
        fourthCityAvailableOffersSuffix: i18n.t(`common.${offerTypeKey}`, {count: fourthCityAvailableOffers}),
        fourthCityAvailableOffersChangeTrend: i18n.t(`common.${fourthCityAvailableOffersChangeTrend}`),
        fourthCityAvailableOffersMonthlyChange,
        fourthCityDescriptive,
        fourthCitySold: formatNumber(fourthCitySold),
        fourthCitySoldSuffix: i18n.t(`common.${offerTypeKey}`, {count: fourthCitySold}),
        fourthCityAdded: formatNumber(fourthCityAdded),

        fifthCity,
        fifthCityPronoun,
        fifthCityUppercased,
        fifthCityAvailableOffers: formatNumber(fifthCityAvailableOffers),
        fifthCityAvailableOffersOfferType: i18n.t(`common.${offerTypeKey}`, {count: fifthCityAvailableOffers}),
        fifthCityAvailableOffersMonthlyChange,
        fifthCityAvailableOffersChangeTrend: i18n.t(`common.${fifthCityAvailableOffersChangeTrend}`),
        fifthCityDescriptive,
        fifthCityAddedOffersPrefix: "pojawiło",
        fifthCityAddedOffers: formatNumber(fifthCityAddedOffers),
        fifthCityAddedOffersSuffix: i18n.t("common.offer", {count: fifthCityAddedOffers}),

        sixthCity,
        sixthCityPronoun,
        sixthCityAvailableOffersChangeTrend: i18n.t(`common.${sixthCityAvailableOffersChangeTrend}`),
        sixthCityAvailableOffersMonthlyChange,
        sixthCityAvailableOffers: formatNumber(sixthCityAvailableOffers),
        sixthCityAvailableOffersSuffix: i18n.t(`common.${offerTypeKey}`, {count: sixthCityAvailableOffers}),
        sixthCityDevelopersOrigin,
        sixthCitySoldChangeTrend: "zniknęło",
        sixthCitySold: formatNumber(sixthCitySold),
        sixthCitySoldSuffix: i18n.t(`common.${offerTypeKey}`, {count: sixthCitySold}),
        sixthCityAdded: formatNumber(sixthCityAdded),
        sixthCityAddedSuffix: i18n.t("common.offer", {count: sixthCityAdded})
    };

    return i18n?.t("home.analyst_comment_offer", variables) ?? "";
};
