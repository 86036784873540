import {useMemo, useRef} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

import {IMonthCityTypeStats} from "../../../../../api/src/db_queries/month_city_type_stats_query";
import {CitySlug, mapCitySlugToLocalizedParamSlug, validateOfferType} from "../../../../../config/cities";
import {formatDate, humanDateFormatLong, humanDateFormatShort} from "../../../../../utils/dates";
import {useAppSelector} from "../../../../../utils/hooks/store_hooks";
import {useAccessDate} from "../../../../../utils/hooks/useAccessDate";
import {FilterOfferType} from "../../../common/app/constants/enums";
import {chartsRoutes, compilePath} from "../../../common/app/routing/charts_routes";
import {mapOfferTypeToValidPath, OfferType} from "../../../common/app/routing/offer_type";
import {Card} from "../../../components/card/Card";
import {MultilineSharedChart} from "../../../components/charts/MultilineShared";
import {Error} from "../../../components/Error";
import {Grid} from "../../../components/Grid";
import {withDateGuard} from "../../../components/hoc/withDateGuard";
import {withGTM} from "../../../components/hoc/withGTM";
import {ChartsLayout as Layout} from "../../../components/layout/charts_layout/ChartsLayout";
import {Division, MathEquation} from "../../../components/MathEquation";
import {selectLatestEntry} from "../../ssr/redux/latest_entry_slice";
import {pageviewAlgolyticsHit} from "../../tracking/algolytics/pageview_hits";
import {usePageview} from "../../tracking/use_pageview";
import {pageviewUserComHit} from "../../tracking/user_com/hit_user_com";
import {ViewType} from "../../tracking/view_type";
import {useGetBiggestRegionsStatsQuery, useGetBiggestRegionsTypeStatsQuery} from "../api/analytical_platform_api";
import {AnalystComment} from "../components/AnalystComment";
import {CountryMap} from "../components/country_map/CountryMap";
import {ExtendableTextBlock} from "../components/ExtendableTextBlock";
import {mapRtkQueryStatusToRequestStatus} from "../utils/rtq_status_to_request_status";

export const HomeHouses = withDateGuard(
    withGTM(() => {
        const {t, i18n} = useTranslation();
        const {currentDate} = useAppSelector(selectLatestEntry);
        const {upperBound, lowerBound} = useAccessDate(currentDate ?? "");
        const history = useHistory();
        usePageview(() => {
            pageviewAlgolyticsHit({offerType: OfferType.HOUSE, viewType: ViewType.HOME});
            pageviewUserComHit();
        });

        const {data: biggestRegionsStatsData} = useGetBiggestRegionsStatsQuery({
            date_start: lowerBound,
            date_end: upperBound,
            scenario: "home"
        });

        const {
            data: biggestRegionsTypeStatsData,
            isSuccess: rtqBiggestRegionsTypeStatsIsSuccess,
            isError: rtqBiggestRegionsTypeStatsIsError
        } = useGetBiggestRegionsTypeStatsQuery({
            date_start: lowerBound,
            date_end: upperBound,
            scenario: "home"
        });

        const biggestRegionsTypeStatsDataStatus = mapRtkQueryStatusToRequestStatus(
            rtqBiggestRegionsTypeStatsIsError,
            rtqBiggestRegionsTypeStatsIsSuccess
        );

        const mapData = useMemo(() => {
            if (biggestRegionsTypeStatsData && biggestRegionsTypeStatsData.length > 0) {
                return biggestRegionsTypeStatsData.filter((month) => month.date === upperBound);
            }
            return [];
        }, [biggestRegionsTypeStatsData]);
        const chart1Ref = useRef(null);
        const chart2Ref = useRef(null);
        const chart3Ref = useRef(null);
        const chart4Ref = useRef(null);
        const chart5Ref = useRef(null);
        const offerType = FilterOfferType.HOUSES;
        const periodDates = `${formatDate(lowerBound, humanDateFormatShort, i18n)} - ${formatDate(
            upperBound,
            humanDateFormatShort,
            i18n
        )}`;
        const currentMonthFormatted = formatDate(upperBound, humanDateFormatLong, i18n);
        const pathOfferType = OfferType.HOUSE;

        const handleCitySelection = (city: CitySlug) => {
            const validOfferType = validateOfferType(city, pathOfferType);
            const targetPath = mapOfferTypeToValidPath(
                validOfferType,
                chartsRoutes[i18n.language].houses.city.root,
                i18n
            );
            const localizedCityParam = mapCitySlugToLocalizedParamSlug(city, i18n.language);
            const compiledPath = compilePath(targetPath, {city: localizedCityParam});
            history.push(compiledPath);
        };
        return (
            <Layout onCitySelection={handleCitySelection}>
                {biggestRegionsStatsData && biggestRegionsTypeStatsData && (
                    <Grid type="home">
                        <Card
                            area="chart1"
                            title={<Trans>{t("home.chart1.title", {periodDates, propertyType: "house"})}</Trans>}
                            helper={<Trans>{t("home.chart1.description", {propertyType: "house"})}</Trans>}
                            chartRef={chart1Ref}
                            withTextBlock
                        >
                            <MultilineSharedChart
                                title={<Trans>{t("home.chart1.title", {periodDates, propertyType: "house"})}</Trans>}
                                colorSet="alternate"
                                data={biggestRegionsTypeStatsData}
                                dataField="avg_price_m2"
                                dataStatus={biggestRegionsTypeStatsDataStatus}
                                height={400}
                                offerType={offerType}
                                unit={` ${t("common.pln_m2")}`}
                                innerRef={chart1Ref}
                                roundValues
                            />

                            <ExtendableTextBlock title={t("home.analyst_comment_heading")} contentHeight={400}>
                                <Error>
                                    <AnalystComment
                                        type="avg_price_m2"
                                        offerType={offerType}
                                        biggestRegionsStatsData={biggestRegionsStatsData}
                                        biggestRegionsTypeStatsData={biggestRegionsTypeStatsData}
                                    />
                                </Error>
                            </ExtendableTextBlock>
                        </Card>

                        <Card
                            area="map"
                            title={
                                <Trans>
                                    {t("home.map.title", {
                                        currentMonth: currentMonthFormatted,
                                        propertyType: "houses"
                                    })}
                                </Trans>
                            }
                            helper={
                                <Trans>
                                    {t("home.map.description", {
                                        currentMonth: currentMonthFormatted,
                                        propertyType: "houses"
                                    })}
                                </Trans>
                            }
                            withTextBlock
                        >
                            <div>
                                <CountryMap<IMonthCityTypeStats>
                                    data={mapData}
                                    dataStatus={biggestRegionsTypeStatsDataStatus}
                                    offerType={offerType}
                                />
                            </div>

                            <ExtendableTextBlock title={t("home.analyst_comment_heading")} contentHeight={700}>
                                <Error>
                                    <AnalystComment
                                        type="available_offers"
                                        offerType={offerType}
                                        biggestRegionsStatsData={biggestRegionsStatsData}
                                        biggestRegionsTypeStatsData={biggestRegionsTypeStatsData}
                                    />
                                </Error>
                            </ExtendableTextBlock>
                        </Card>

                        <Card
                            area="chart2"
                            title={<Trans>{t("home.chart2.title", {periodDates, propertyType: "houses"})}</Trans>}
                            helper={<Trans>{t("home.chart2.description", {propertyType: "houses"})}</Trans>}
                            chartRef={chart2Ref}
                        >
                            <MultilineSharedChart
                                title={<Trans>{t("home.chart2.title", {periodDates, propertyType: "houses"})}</Trans>}
                                colorSet="alternate"
                                data={biggestRegionsTypeStatsData}
                                dataField="available_offers"
                                dataStatus={biggestRegionsTypeStatsDataStatus}
                                height={300}
                                offerType={offerType}
                                innerRef={chart2Ref}
                            />
                        </Card>

                        <Card
                            area="chart3"
                            title={<Trans>{t("home.chart3.title", {periodDates, propertyType: "houses"})}</Trans>}
                            helper={
                                <>
                                    {t("home.chart3.description", {propertyType: "houses"})}
                                    <br />
                                    <br />
                                    <MathEquation
                                        leftSide={t("common.formulas.number_of_offers")}
                                        rightSide={`${t("common.formulas.sold_offers")} + ${t(
                                            "common.formulas.available_offers"
                                        )}`}
                                    />
                                    <br />
                                    <MathEquation
                                        leftSide={t("common.formulas.demand_indicator")}
                                        rightSide={
                                            <Division
                                                dividend={t("common.formulas.sold_offers")}
                                                divisor={t("common.formulas.number_of_offers")}
                                            />
                                        }
                                    />
                                </>
                            }
                            chartRef={chart3Ref}
                        >
                            <MultilineSharedChart
                                title={<Trans>{t("home.chart3.title", {periodDates, propertyType: "houses"})}</Trans>}
                                colorSet="alternate"
                                data={biggestRegionsTypeStatsData}
                                dataField="demand"
                                dataStatus={biggestRegionsTypeStatsDataStatus}
                                height={300}
                                unit="%"
                                valueMultiplier={100}
                                offerType={offerType}
                                innerRef={chart3Ref}
                            />
                        </Card>

                        <Card
                            area="chart4"
                            title={<Trans>{t("home.chart4.title", {periodDates, propertyType: "houses"})}</Trans>}
                            helper={<Trans>{t("home.chart4.description", {propertyType: "houses"})}</Trans>}
                            chartRef={chart4Ref}
                        >
                            <MultilineSharedChart
                                title={<Trans>{t("home.chart4.title", {periodDates, propertyType: "houses"})}</Trans>}
                                colorSet="alternate"
                                data={biggestRegionsTypeStatsData}
                                dataField="sold"
                                dataStatus={biggestRegionsTypeStatsDataStatus}
                                height={300}
                                offerType={offerType}
                                innerRef={chart4Ref}
                            />
                        </Card>

                        <Card
                            area="chart5"
                            title={<Trans>{t("home.chart5.title", {periodDates, propertyType: "houses"})}</Trans>}
                            helper={<Trans>{t("home.chart5.description", {propertyType: "houses"})}</Trans>}
                            chartRef={chart5Ref}
                        >
                            <MultilineSharedChart
                                title={<Trans>{t("home.chart5.title", {periodDates, propertyType: "houses"})}</Trans>}
                                colorSet="alternate"
                                data={biggestRegionsTypeStatsData}
                                dataField="added"
                                dataStatus={biggestRegionsTypeStatsDataStatus}
                                height={300}
                                offerType={offerType}
                                innerRef={chart5Ref}
                            />
                        </Card>
                    </Grid>
                )}
            </Layout>
        );
    })
);
