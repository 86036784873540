import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {css, useTheme} from "@emotion/react";
import {FormikForm} from "@pg-design/formik-utils";
import {
    calculateRemSize,
    flex,
    flexAbsoluteCenter,
    flexDirection,
    mb,
    mr,
    mt,
    onDesktop,
    underline,
    w100
} from "@pg-design/helpers-css";
import {InfoIcon} from "@pg-design/icons";
import {Link} from "@pg-design/link";
import {Modal} from "@pg-design/modal";
import {Select} from "@pg-design/select";
import {Text} from "@pg-design/text";
import {Form, Formik, FormikHelpers} from "formik";
import i18next from "i18next";
import * as Yup from "yup";

import {dataLayer, getRecentGTMKeyValue} from "../../../../../../utils/data_layer";
import {getPayloadError} from "../../../../../../utils/get_payload_error";
import {useAppDispatch, useAppSelector} from "../../../../../../utils/hooks/store_hooks";
import {Department, UserType} from "../../../../../../utils/shared_types/user_model";
import {chartsRoutes} from "../../../../common/app/routing/charts_routes";
import {flexGrow, flexShrink} from "../../../../components/css_helpers/flex";
import {handleFormikSubmit} from "../../../../components/form/handle_submit";
import {SubmitFormikButton} from "../../../../components/SubmitFormikButton";
import {departmentSelectOptions} from "../../../../components/types/department_select_options";
import {userTypeSelectOptions} from "../../../../components/types/user_type_select_options";
import {ApiError} from "../../../../store/IApiError";
import {useRegisterMutation} from "../../../auth/api/registerApi";
import {setAuthModal} from "../../../auth/auth_slice";
import {DataProtectionNotice} from "../../../auth/components/auth_modal/DataProtectionNotice";
import {ChangeModalTypeLink} from "../../../auth/components/ChangeModalTypeLink";
import {selectUserFormsSharedData} from "../../users_slice";
import {IRegisterFormSubmissionResult, IRegisterFormValues} from "./register_form.types";
import {RegisterFormSuccessMessage} from "./RegisterFormSuccessMessage";

export const RegisterForm = () => {
    const userFormsSharedData = useAppSelector(selectUserFormsSharedData);
    const [register, registerMutation] = useRegisterMutation({fixedCacheKey: "registerUser"});
    const [formSubmissionResult, setFormSubmissionResult] = useState<null | IRegisterFormSubmissionResult>(null);
    const {t, i18n} = useTranslation();
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const [, setSelectedUserType] = useState(
        //Object is very much defined
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        userTypeSelectOptions(i18n).find(({value}) => value === UserType.DEVELOPER).label
    );
    const [, setSelectedDepartment] = useState(
        // Object is very much defined
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        departmentSelectOptions(i18n, true).find(({value}) => value === Department.MANAGEMENT_DEPARTMENT).label
    );

    const getUserTypePlaceholder = (userType: UserType): string => {
        switch (userType) {
            case UserType.JOURNALIST:
                return t("common.editorial_name");
            case UserType.INSTITUTION:
                return t("common.institution_name");
            default:
                return t("common.company_name");
        }
    };

    useEffect(() => {
        return () => {
            registerMutation.reset();
        };
    }, []);

    const initialFormValues: IRegisterFormValues = {
        ...userFormsSharedData,
        bigdata_marketing_consent: false,
        first_name: "",
        last_name: "",
        phone: "",
        user_type: UserType.OTHER,
        department: Department.BROKER_DEPARTMENT,
        company_name: ""
    };

    const validationSchema = Yup.object({
        email: Yup.string().email(t("auth.errors.invalid_email")).required(t("auth.errors.field_cannot_be_empty")),
        password: Yup.string().required(t("auth.errors.field_cannot_be_empty")),
        bigdata_marketing_consent: Yup.boolean(),
        first_name: Yup.string().required(t("auth.errors.field_cannot_be_empty")),
        last_name: Yup.string().required(t("auth.errors.field_cannot_be_empty")),
        phone: Yup.string().required(t("auth.errors.field_cannot_be_empty")),
        user_type: Yup.number(),
        department: Yup.number(),
        company_name: Yup.string().required(t("auth.errors.field_cannot_be_empty"))
    });

    const onSubmit = (formValues: IRegisterFormValues, helpers: FormikHelpers<IRegisterFormValues>) =>
        handleFormikSubmit(
            register({
                email: formValues.email,
                password: formValues.password,
                bigdata_marketing_consent: formValues.bigdata_marketing_consent,
                language: i18next.language,
                first_name: formValues.first_name,
                last_name: formValues.last_name,
                phone: formValues.phone,
                user_type: formValues.user_type,
                company_name: formValues.company_name,
                department:
                    formValues.user_type === UserType.DEVELOPER || formValues.user_type === UserType.BROKER
                        ? formValues.department
                        : undefined
            }).unwrap(),
            helpers,
            {
                onSuccess: (userData) => {
                    // registerUserComHit(userData);

                    if (userData?.user_type === UserType.JOURNALIST) {
                        setFormSubmissionResult("journalist");
                    } else {
                        setFormSubmissionResult("nonJournalistUser");
                    }

                    dataLayer({
                        event: "register_success",
                        position: getRecentGTMKeyValue("position"),
                        position_name: getRecentGTMKeyValue("position_name"),
                        position_id: getRecentGTMKeyValue("position_id")
                    });

                    helpers.setSubmitting(false);
                },
                onError: (error: ApiError) => {
                    const errors = error.data;
                    //  Handle field errors
                    if (errors) {
                        //  No docs about possible errors

                        for (const payloadErrorItemKey of Object.keys(errors)) {
                            const errorData = errors[payloadErrorItemKey];
                            helpers.setFieldError(payloadErrorItemKey, getPayloadError(errorData, i18n));
                        }
                    }

                    // Handle "different" errors
                    if (errors && "non_field_errors" in errors) {
                        toast.error(getPayloadError(errors["non_field_errors"], i18n));
                    }

                    if (!errors) {
                        toast.error(() => t("auth.errors.generic"));
                    }

                    helpers.setSubmitting(false);
                }
            }
        );

    if (formSubmissionResult) {
        return <RegisterFormSuccessMessage registerResult={formSubmissionResult} />;
    }

    return (
        <>
            <Modal.Header>
                <Text variant="headline_6" as="span">
                    {t("auth.request_access")}
                </Text>
            </Modal.Header>

            <Modal.Content>
                <Formik initialValues={initialFormValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {({values, handleBlur, isSubmitting, touched, errors, setFieldValue}) => (
                        // <RegisterFormSubmitHandler setSubmissionResult={setSubmissionResult} />
                        <Form>
                            <Text color={theme.colors.gray[700]} css={mb(5)} variant="body_copy_2" align="start">
                                {t("auth.data_access_verification_explainer")}
                            </Text>

                            <div css={formContentWrapper}>
                                <div>
                                    <FormikForm.Input
                                        css={mb(1.5)}
                                        name="first_name"
                                        placeholder={t("common.name")}
                                        onBlur={handleBlur}
                                    />

                                    <FormikForm.Input
                                        css={mb(1.5)}
                                        name="last_name"
                                        placeholder={t("common.last_name")}
                                        onBlur={handleBlur}
                                    />

                                    <FormikForm.Input
                                        css={mb(1.5)}
                                        name="company_name"
                                        placeholder={getUserTypePlaceholder(values.user_type)}
                                        onBlur={handleBlur}
                                    />

                                    <Select
                                        css={mb(1.5)}
                                        placeholder={t("common.who_are_you")}
                                        onChange={(e) => {
                                            const newUserType = e as {label: string; value: UserType};
                                            setFieldValue("user_type", newUserType.value);
                                            setSelectedUserType(newUserType.label);
                                        }}
                                        onBlur={handleBlur}
                                        name="user_type"
                                        options={userTypeSelectOptions(i18n)}
                                        required
                                    />

                                    {(values.user_type === UserType.DEVELOPER ||
                                        values.user_type === UserType.BROKER) && (
                                        <Select
                                            css={mb(1.5)}
                                            maxMenuHeight={150}
                                            name="department"
                                            placeholder={t("common.function")}
                                            onChange={(e: unknown) => {
                                                const newDepartment = e as {label: string; value: Department};
                                                setFieldValue("department", newDepartment.value);
                                                setSelectedDepartment(newDepartment.label);
                                            }}
                                            onBlur={handleBlur}
                                            options={departmentSelectOptions(
                                                i18n,
                                                values.user_type === UserType.DEVELOPER
                                            )}
                                            required
                                        />
                                    )}

                                    <FormikForm.Input
                                        css={mb(1.5)}
                                        name="phone"
                                        placeholder={t("common.phone")}
                                        onBlur={handleBlur}
                                    />
                                </div>

                                <div>
                                    <FormikForm.Input
                                        css={mb(1.5)}
                                        name="email"
                                        placeholder={t("common.email")}
                                        onBlur={handleBlur}
                                    />

                                    <FormikForm.Input
                                        css={mb(1.5)}
                                        name="password"
                                        type="password"
                                        onBlur={handleBlur}
                                        placeholder={t("auth.password")}
                                    />

                                    <div css={[flex(), mb(3)]}>
                                        <InfoIcon
                                            css={[mr(1), flexShrink(0), flexGrow(0)]}
                                            fill={theme.colors.gray[600]}
                                            size="1.6"
                                        />
                                        <Text color={theme.colors.gray[600]} variant="info_txt_2">
                                            {t("auth.consent_explainer")}
                                        </Text>
                                    </div>

                                    <FormikForm.Checkbox
                                        css={flex("flex-start")}
                                        checkedMarkColor="var(--green-checkbox-checked)"
                                        name="bigdata_marketing_consent"
                                        onBlur={handleBlur}
                                        labelContent={
                                            <Text css={!errors.bigdata_marketing_consent && mb(3)} variant="info_txt_1">
                                                {t("auth.marketing_consent")}
                                            </Text>
                                        }
                                        id="register_bigdata_marketing_consent"
                                    />

                                    {touched.bigdata_marketing_consent && errors.bigdata_marketing_consent && (
                                        <Text css={[mt(1.5), mb(3)]} variant="info_txt_2" color="red">
                                            {errors.bigdata_marketing_consent}
                                        </Text>
                                    )}

                                    <DataProtectionNotice css={dataProtectionNotice} />
                                </div>
                            </div>

                            <Text color={theme.colors.gray[600]} variant="info_txt_2" align="center" css={mt(4)}>
                                {t("common.i_accept")}{" "}
                                <Link
                                    underline
                                    size="x-small"
                                    css={linkText}
                                    href={chartsRoutes[i18n.language].terms}
                                    target="_blank"
                                >
                                    {t("common.terms_of_service")}
                                </Link>
                            </Text>

                            <div css={buttons}>
                                <SubmitFormikButton
                                    disabled={isSubmitting || registerMutation.isLoading || registerMutation.isSuccess}
                                    css={button}
                                    copy={t("auth.register")}
                                />

                                <ChangeModalTypeLink
                                    textContent={t("auth.already_registered")}
                                    linkContent={t("auth.login")}
                                    linkAction={() => dispatch(setAuthModal("login"))}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal.Content>
        </>
    );
};

const formContentWrapper = css`
    ${onDesktop(css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: ${calculateRemSize(3)};
    `)};
`;

const buttons = css`
    ${mt(4)};
    ${flexAbsoluteCenter};
    ${flexDirection("column")};
`;

const button = css`
    ${mb(3)};
    ${w100};

    ${onDesktop(css`
        width: 42.4rem;
    `)}
`;

const dataProtectionNotice = css`
    text-align: left;
    ${mb(3)};
    margin-left: 2.8rem;
`;

const linkText = css`
    &:any-link {
        ${underline};
    }

    color: inherit;
`;
