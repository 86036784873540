import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import {IMonthCityStats} from "../../../../../api/src/db_queries/month_city_stats_query";
import {IMonthCityTypeStats} from "../../../../../api/src/db_queries/month_city_type_stats_query";
import {IMonthDistrictStats} from "../../../../../api/src/db_queries/month_district_stats_query";
import {IMonthDistrictTypeStats} from "../../../../../api/src/db_queries/month_district_type_stats_query";
import {IQuarterCityStats} from "../../../../../api/src/db_queries/quarter_city_stats_query";
import {IQuarterCityTypeStats} from "../../../../../api/src/db_queries/quarter_city_type_stats_query";
import {CitySlug} from "../../../../../config/cities";
import {RegionType} from "../../../../../config/regions";
import {MagicCityKeyword} from "../../../../../utils/hooks/useAPIRequest";
import prepare_headers from "../../../store/prepare_headers";

export interface AnalyticalPlatformApiParams {
    slug_city?: CitySlug | MagicCityKeyword | null;
    slug_agglomeration?: CitySlug;
    date_start: string; // yyyy-MM-dd date format required
    date_end: string; // yyyy-MM-dd date format required
    scenario: string;
    region_type?: RegionType;
    offer_type?: string;
}

export const analyticalPlatformApi = createApi({
    reducerPath: "platformApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `/api-charts/`,
        prepareHeaders: prepare_headers,
        credentials: "include"
    }),
    endpoints: (builder) => ({
        getMonthCityStats: builder.query<IMonthCityStats[], AnalyticalPlatformApiParams>({
            query: (params) => ({
                url: params.slug_city ? "month-city-stats/" : "month-agglomeration-stats/",
                method: "GET",
                params
            })
        }),
        getMonthCityTypeStats: builder.query<IMonthCityTypeStats[], AnalyticalPlatformApiParams>({
            query: (params) => ({
                url: params.slug_city ? "month-city-type-stats/" : "month-agglomeration-type-stats/",
                method: "GET",
                params
            })
        }),
        getMonthCityFlatStats: builder.query<IMonthCityTypeStats[], AnalyticalPlatformApiParams>({
            query: (params) => ({
                url: params.slug_city
                    ? "month-city-type-stats/?offer_type=Mieszkania"
                    : "month-agglomeration-type-stats/?offer_type=Mieszkania",
                method: "GET",
                params
            })
        }),
        getQuarterCityStats: builder.query<IQuarterCityStats[], AnalyticalPlatformApiParams>({
            query: (params) => ({
                url: params.slug_city ? "quarter-city-stats/" : "quarter-agglomeration-stats/",
                method: "GET",
                params
            })
        }),
        getQuarterCityTypeStats: builder.query<IQuarterCityTypeStats[], AnalyticalPlatformApiParams>({
            query: (params) => ({
                url: params.slug_city ? "quarter-city-type-stats/" : "quarter-agglomeration-type-stats/",
                method: "GET",
                params
            })
        }),
        getMonthDistrictStats: builder.query<
            IMonthDistrictTypeStats[] | IMonthDistrictStats[],
            AnalyticalPlatformApiParams
        >({
            query: (params) => ({
                url: params.offer_type ? `month-district-type-stats/` : "month-district-stats/",
                method: "GET",
                params
            })
        }),
        getBiggestRegionsStats: builder.query<IMonthCityStats[], AnalyticalPlatformApiParams>({
            query: (params) => ({
                url: "biggest-regions-stats/",
                method: "GET",
                params
            })
        }),
        getBiggestRegionsTypeStats: builder.query<IMonthCityTypeStats[], AnalyticalPlatformApiParams>({
            query: (params) => ({
                url: "biggest-regions-type-stats/",
                method: "GET",
                params
            })
        }),
        getBiggestRegionsTypeStatsFromLastMonth: builder.query<
            IMonthCityTypeStats[],
            Omit<AnalyticalPlatformApiParams, "date_start" | "date_end">
        >({
            query: (params) => ({
                url: "biggest-regions-type-stats-from-last-month/",
                method: "GET",
                params
            })
        })
    })
});

export const {
    useGetMonthCityStatsQuery,
    useGetMonthCityTypeStatsQuery,
    useGetQuarterCityStatsQuery,
    useGetQuarterCityTypeStatsQuery,
    useGetMonthDistrictStatsQuery,
    useGetBiggestRegionsStatsQuery,
    useGetBiggestRegionsTypeStatsQuery,
    useGetBiggestRegionsTypeStatsFromLastMonthQuery
} = analyticalPlatformApi;
