import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {css, Theme, useTheme} from "@emotion/react";
import {
    color,
    flex,
    flexAlignCenter,
    h100,
    ml,
    mt,
    position,
    positionRelative,
    w100,
    zIndex
} from "@pg-design/helpers-css";
import {InfoIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";
import {format} from "date-fns";
import {i18n} from "i18next";

import {CitySlug} from "../../../../../../../config/cities";
import {useAppSelector} from "../../../../../../../utils/hooks/store_hooks";
import {useGetBiggestRegionsTypeStatsFromLastMonthQuery} from "../../../../analytical_platform/api/analytical_platform_api";
import {selectLatestEntry} from "../../../../ssr/redux/latest_entry_slice";
import {AnimatedChartBar as Bar} from "./AnimatedChartBar";

interface IChartValues {
    [key: string]: {
        name: string;
        hsl: [number, number, number];
        value: number;
    };
}

export const AnimatedChart = () => {
    const {t, i18n} = useTranslation();
    const {currentDate} = useAppSelector(selectLatestEntry);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore withDateGuard on Hero makes sure currentDate is not null
    const lastMonth = format(new Date(currentDate), "yyyy-MM-dd");
    const lastMonthDate = new Date(lastMonth);
    const {data: chartData} = useGetBiggestRegionsTypeStatsFromLastMonthQuery({scenario: "home"});

    const theme = useTheme();

    const total: number = useMemo(() => {
        if (chartData) {
            const flatsData = chartData.filter((data) => data.offer_type === "Mieszkania");
            return flatsData.reduce((acc, curr) => acc + curr.avg_price_m2, 0) / flatsData.length;
        }
        return 0;
    }, [chartData]);

    const values: IChartValues = useMemo(() => {
        const newValues = {...initialChartValues(i18n)};

        if (chartData) {
            const flatsData = chartData.filter((data) => data.offer_type === "Mieszkania");
            for (const city of flatsData) {
                newValues[city.slug_city].value = city.avg_price_m2;
            }
        }

        return newValues;
    }, [chartData, i18n.language]);

    const month = lastMonthDate.toLocaleString("en", {month: "long"}).toLowerCase();
    const monthName = t(`month.${month}.locative`);
    const year = lastMonthDate.getFullYear();

    return (
        <div css={wrapper}>
            <div>
                <div>
                    <div css={chart}>
                        {Object.entries(values).map(([slug, values], i) => (
                            <Bar
                                key={slug}
                                citySlug={slug as CitySlug}
                                total={total}
                                animationDelay={(i + 1) / 4}
                                {...values}
                            />
                        ))}
                    </div>
                </div>

                <div css={chartInfo}>
                    <InfoIcon fill="orange" size="2" />

                    <Text css={color(theme.colors.gray[700])} variant="info_txt_1">
                        {t("landing.hero.chart_info", {date: `${monthName} ${year}`})}
                    </Text>
                </div>
            </div>
        </div>
    );
};

const initialChartValues = (i18n: i18n): IChartValues => ({
    gdansk: {
        name: i18n.t("city.gdansk.nominative"),
        hsl: [288, 55, 61],
        value: 0
    },
    krakow: {
        name: i18n.t("city.krakow.nominative"),
        hsl: [338, 100, 63],
        value: 0
    },
    lodz: {
        name: i18n.t("city.lodz.nominative"),
        hsl: [27, 87, 59],
        value: 0
    },
    poznan: {
        name: i18n.t("city.poznan.nominative"),
        hsl: [204, 81, 60],
        value: 0
    },
    warszawa: {
        name: i18n.t("city.warszawa.nominative"),
        hsl: [50, 87, 59],
        value: 0
    },
    wroclaw: {
        name: i18n.t("city.wroclaw.nominative"),
        hsl: [94, 61, 57],
        value: 0
    },
    katowice: {
        name: `Katowice${String.fromCharCode(160)}/${String.fromCharCode(160)}GZM`,
        hsl: [173, 66, 63],
        value: 0
    }
});

const wrapper = (theme: Theme) => css`
    ${positionRelative};

    & > div {
        ${positionRelative};
        ${zIndex(10)};
        ${w100};
        ${h100};

        & > div:first-of-type {
            height: 500px;
            ${w100};
            ${flex("end", "center")};
        }
    }

    &:before,
    &:after {
        content: "";
        ${position("absolute")};
        ${w100};
        top: 50%;
    }

    &:after {
        height: 90%;
        bottom: 0;
        left: 0;
        right: 0;
        transform: translateY(-65%);
        background: repeating-linear-gradient(
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0) 60px,
            #e0e0e0 60px,
            #e0e0e0 61px
        );
        ${zIndex(9)};
    }

    @media (min-width: ${theme.breakpoints.sm}) {
        &:after {
            height: 75%;
            transform: translateY(-50%);
        }
    }
`;

const chart = css`
    display: grid;
    grid-template-columns: repeat(7, 14%);
    align-items: end;
    ${w100};
`;

const chartInfo = (theme: Theme) => css`
    ${flexAlignCenter};
    gap: 1.6rem;
    ${mt(10)};
    ${ml(0)};

    @media (min-width: ${theme.breakpoints.sm}) {
        ${ml(2)};
    }
`;
