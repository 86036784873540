import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {css, Theme} from "@emotion/react";
import {getTextVariantStyleFor} from "@pg-design/text";

import {IMonthCityStats} from "../../../../../api/src/db_queries/month_city_stats_query";
import {IMonthCityTypeStats} from "../../../../../api/src/db_queries/month_city_type_stats_query";
import {useAppSelector} from "../../../../../utils/hooks/store_hooks";
import {AccessStatus, useAccessStatus} from "../../../../../utils/hooks/useAccessStatus";
import {useTypeFilteredData} from "../../../../../utils/hooks/useTypeFilteredData";
import {FilterOfferType} from "../../../common/app/constants/enums";
import {analystCommentOffer} from "../../../common/meta_data/analyst_comment_offer";
import {analystCommentPrices} from "../../../common/meta_data/analyst_comment_prices";
import {withDateGuard} from "../../../components/hoc/withDateGuard";
import {selectLatestEntry} from "../../ssr/redux/latest_entry_slice";
import {LockedComment} from "./locked_comment/LockedComment";

interface IProps {
    type: "avg_price_m2" | "available_offers";
    offerType: FilterOfferType | null;
    biggestRegionsStatsData: IMonthCityStats[];
    biggestRegionsTypeStatsData: IMonthCityTypeStats[];
}

export const AnalystComment = withDateGuard(
    ({type, offerType, biggestRegionsStatsData, biggestRegionsTypeStatsData}: IProps) => {
        const {currentDate} = useAppSelector(selectLatestEntry);
        const {i18n} = useTranslation();
        const accessStatus = useAccessStatus();
        const filteredCityTypeData = useTypeFilteredData(biggestRegionsTypeStatsData, offerType ?? undefined);
        const data = offerType ? filteredCityTypeData : biggestRegionsStatsData;

        const commentContent: string = useMemo(() => {
            if (data && data.length > 0) {
                if (type === "avg_price_m2") {
                    return analystCommentPrices(data as [], currentDate ?? "", offerType, i18n);
                }
                if (type === "available_offers") {
                    return analystCommentOffer(data as [], currentDate ?? "", offerType, i18n);
                }
            }
            return "";
        }, [data, i18n.language, offerType]);

        return accessStatus === AccessStatus.FULL ? (
            <div dangerouslySetInnerHTML={{__html: commentContent}} css={text} />
        ) : (
            <LockedComment />
        );
    }
);

const text = (theme: Theme) => css`
    ${getTextVariantStyleFor("body_copy_1")(theme)};
    color: ${theme.colors.gray[700]};
`;
