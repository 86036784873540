import {combineReducers, configureStore} from "@reduxjs/toolkit";

import {isDevelopment} from "../common/app/read_charts_web_environment_variables";
import {mapTypeSlice} from "../components/map/redux/map_type_slice";
import {analyticalPlatformApi} from "../features/analytical_platform/api/analytical_platform_api";
import {onboardingSlice} from "../features/analytical_platform/redux/onboarding_slice";
import {appStatusSlice} from "../features/app_status/app_status_slice";
import {authApi} from "../features/auth/api/authApi";
import {authSlice} from "../features/auth/auth_slice";
import {userComApi} from "../features/monitoring/api/user_com_api";
import {newshubApi} from "../features/newshub/api/newshub_api";
import {vendorsApi} from "../features/newshub/api/vendors_api";
import {regionsSlice} from "../features/newshub/redux/regions_slice";
import {vendorsSlice} from "../features/newshub/redux/vendors_slice";
import {inquiryApi} from "../features/publications/api/inquiry_api";
import {publicationsApi} from "../features/publications/api/publications_api";
import {latestEntrySlice} from "../features/ssr/redux/latest_entry_slice";
import {staticContentApi} from "../features/static_content/api/static_content_api";
import {resetPasswordApi} from "../features/users/api/resetPasswordApi";
import {sendVerificationLinkApi} from "../features/users/api/sendVerificationLinkApi";
import {usersApi} from "../features/users/api/usersApi";
import {users_slice} from "../features/users/users_slice";

export const rootReducer = combineReducers({
    //rootState
    appStatus: appStatusSlice.reducer,
    auth: authSlice.reducer,
    [authApi.reducerPath]: authApi.reducer,
    users: users_slice.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [sendVerificationLinkApi.reducerPath]: sendVerificationLinkApi.reducer,
    [resetPasswordApi.reducerPath]: resetPasswordApi.reducer,
    [publicationsApi.reducerPath]: publicationsApi.reducer,
    [inquiryApi.reducerPath]: inquiryApi.reducer,
    onboarding: onboardingSlice.reducer,
    [newshubApi.reducerPath]: newshubApi.reducer,
    vendors: vendorsSlice.reducer,
    regions: regionsSlice.reducer,
    [vendorsApi.reducerPath]: vendorsApi.reducer,
    mapType: mapTypeSlice.reducer,
    latestEntry: latestEntrySlice.reducer,
    [staticContentApi.reducerPath]: staticContentApi.reducer,
    [userComApi.reducerPath]: userComApi.reducer,
    [analyticalPlatformApi.reducerPath]: analyticalPlatformApi.reducer
});

export const createStore = (preloadedState?: IRootState) => {
    const middlewares = [
        usersApi.middleware,
        authApi.middleware,
        sendVerificationLinkApi.middleware,
        resetPasswordApi.middleware,
        publicationsApi.middleware,
        inquiryApi.middleware,
        newshubApi.middleware,
        vendorsApi.middleware,
        staticContentApi.middleware,
        userComApi.middleware,
        analyticalPlatformApi.middleware
    ];

    if (!preloadedState) {
        return configureStore({
            reducer: rootReducer,
            middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
            devTools: isDevelopment
        });
    }

    return configureStore({
        preloadedState: preloadedState,
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
        devTools: isDevelopment
    });
};

type IRootReducer = typeof rootReducer;
export type IRootState = ReturnType<IRootReducer>;
export type IBDStore = ReturnType<typeof createStore>;
export type AppDispatch = IBDStore["dispatch"];
